// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-branching-js": () => import("./../../../src/pages/branching.js" /* webpackChunkName: "component---src-pages-branching-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-initialize-js": () => import("./../../../src/pages/initialize.js" /* webpackChunkName: "component---src-pages-initialize-js" */),
  "component---src-pages-ssh-keys-js": () => import("./../../../src/pages/ssh-keys.js" /* webpackChunkName: "component---src-pages-ssh-keys-js" */)
}

